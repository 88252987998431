import {
  Box,
  ListItem,
  ListItemText,
  Collapse,
  TableCell,
  TableRow,
  IconButton,
  makeStyles,
} from "@material-ui/core";
import {
  Clienteles,
  upsertClienteleDays,
} from "../../../api/detailedPlanningApiClient";

import { NavLink } from "react-router-dom";

import {
  ChangeEvent,
  Dispatch,
  forwardRef,
  SetStateAction, useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useTranslation } from "react-i18next";

import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";

import CustomerCollapsibleTable from "./CustomerCollapsibleTable";

import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import { STATUS_TYPE } from "../dropdowns/StatusDropdown";
import { useForm, UseFormProps } from "react-hook-form";

import { useSnackbar } from "notistack";
import InputPeriodDate from "./InputPeriodDate";
import Checkbox from "@mui/material/Checkbox";
import { Menu } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file

interface TableRowProps {
  initiallyOpen?: boolean;
  clienteles: Clienteles;
  selectedClienteles: Clienteles[];
  setSelectedClienteles: Dispatch<SetStateAction<Clienteles[]>>;
  openDate: boolean;
  toggleDate: (index:number)=>void;
  index: number;
}

interface FormValues {
  periodDate: { startDate: string; endDate: string; key: string };
  selectedClientele: Clienteles[];
}

const navlinkStyle = {
  color: "black",
  textDecoration: "none",
  width: "100%",
};

const CustomerTableRow = forwardRef(
  (
    {
      clienteles,
      initiallyOpen,
      selectedClienteles,
      setSelectedClienteles,
      openDate,
      toggleDate,
        index
    }: TableRowProps,
    ref
  ) => {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const [open, setOpen] = useState(initiallyOpen ?? false);
    const classes = useRowStyles();

    const defaultValues = useMemo(
      () => ({
        periodDate: {
          startDate: clienteles.dateFrom,
          endDate: clienteles.dateTo,
          key: "periodDate",
        },
        selectedClientele: selectedClienteles,
      }),
      [clienteles.dateFrom, clienteles.dateTo, selectedClienteles]
    );
    const { control, getValues, formState, reset, watch } = useForm<FormValues>(
      {
        defaultValues: defaultValues,
      } as UseFormProps<FormValues, any> | undefined
    );

    // const values = getValues();
    // const [selectionRange, setSelectionRange] = useState({
    //   startDate: values.periodDate
    //     ? new Date(values.periodDate.startDate)
    //     : new Date(),
    //   endDate: values.periodDate
    //     ? new Date(values.periodDate.endDate)
    //     : new Date(),
    //   key: "periodDate",
    // });

    const periodDateWatched = watch("periodDate");

    const [contextMenu, setContextMenu] = useState<{
      mouseX: number;
      mouseY: number;
    } | null>(null);

    const handleContextMenu = (event: React.MouseEvent) => {
      event.preventDefault();
      setContextMenu(
        contextMenu === null
          ? {
              mouseX: event.clientX + 2,
              mouseY: event.clientY - 6,
            }
          : // repeated contextmenu when it is already open closes it with Chrome 84 on Ubuntu
            // Other native context menus might behave different.
            // With this behavior we prevent contextmenu from the backdrop to re-locale existing context menus.
            null
      );
    };

    function navigateToDetailPlaning() {
      localStorage.setItem(
        "detailedPlaning",
        JSON.stringify({
          year: clienteles.year,
          weekOfYear: clienteles.weekOfYear,
          importVersion: clienteles.version,
          clientele: clienteles.clientele,
        })
      );
      window.open(
        "/" + t("detailed-planning" as any).toLowerCase() + `/details`
      );
      handleClose();
    }

    const handleClose = () => {
      setContextMenu(null);
    };

    const selectStatus = (status: string) => {
      switch (status) {
        case STATUS_TYPE.FINISHED:
          return "green";
        case STATUS_TYPE.IN_RELEASE:
          return "lightgreen";
        case STATUS_TYPE.READY:
          return "orange";
        case STATUS_TYPE.IN_PROGRESS:
          return "gold";
        case STATUS_TYPE.UNKNOWN:
          return "gray";
        case STATUS_TYPE.IN_GRAPHIC:
          return "blue";
        case STATUS_TYPE.CRITICAL:
          return "red";
        default:
          return "";
      }
    };

    const setPeriodDate = useCallback(async () => {
      const isFormEdited = formState.isDirty;
      if (isFormEdited === false) return;

      function join(date: Date, options: any, separator: string) {
        function format(option: any) {
          let formatter = new Intl.DateTimeFormat("de", option);
          return formatter.format(date);
        }
        return options.map(format).join(separator);
      }

      let options = [
        { year: "numeric" },
        { month: "2-digit" },
        { day: "2-digit" },
      ];

      let startDate = join(
        new Date(getValues().periodDate.startDate) as Date,
        options,
        "-"
      );

      let endDate = join(
        new Date(getValues().periodDate.endDate) as Date,
        options,
        "-"
      );

      try {
        await upsertClienteleDays(
          {
            version: clienteles.version,
            year: clienteles.year,
            weekOfYear: clienteles.weekOfYear,
            clientele: clienteles.clientele,
          },
          {
            dateFrom: startDate,
            dateTo: endDate,
          }
        );
        enqueueSnackbar(t("successfully-applied" as any), {
          variant: "success",
        });
      } catch (e) {
        enqueueSnackbar(t("error"), { variant: "error" });
      }

      // formState.isDirty = false;
    },[clienteles.clientele, clienteles.version, clienteles.weekOfYear, clienteles.year, enqueueSnackbar, formState.isDirty, getValues,t]);

    const getSelected = () => {
      if (selectedClienteles.includes(clienteles)) {
        return true;
      }
      return false;
    };

    const changeSelectedClienteles = (e: ChangeEvent<HTMLInputElement>) => {
      if (e.target.checked) {
        setSelectedClienteles([...selectedClienteles, clienteles]);
      } else {
        setSelectedClienteles(
          selectedClienteles.filter((item) => item !== clienteles)
        );
      }
    };

    useEffect(() => {
      reset(defaultValues as FormValues);
    }, [clienteles, defaultValues, reset]);

    useEffect(() => {
      setPeriodDate();
    }, [periodDateWatched, setPeriodDate]);

    return (
      <>
        <TableRow className={classes.root}>
          <TableCell className={classes.checkbox} component="th" scope="row">
            <Checkbox
              checked={getSelected()}
              onChange={(e) => {
                changeSelectedClienteles(e);
              }}
            />
          </TableCell>

          <TableCell
            style={{ cursor: "context-menu" }}
            onContextMenu={handleContextMenu}
            component="th"
            scope="row"
          >
            <NavLink
              style={({ isActive }) => ({
                ...navlinkStyle,
                color: isActive ? "#0d47a1" : "inherit",
              })}
              to={"/" + t("detailed-planning").toLowerCase() + `/clientele`}
              state={{
                clientele: clienteles.clientele,
                year: clienteles.year,
                weekOfYear: clienteles.weekOfYear,
                importVersion: clienteles.version,
              }}
            >
              <ListItem button onClick={() => setOpen(!open)}>
                <ListItemText>
                  <Box
                    display="flex"
                    alignItems="center"
                    className={classes.texStyles}
                  >
                    <FiberManualRecordIcon
                      style={{
                        fontSize: "14px",
                        marginRight: "8px",
                        color: selectStatus(clienteles.status),
                      }}
                    />
                    {`${clienteles.clientele}`}
                  </Box>
                </ListItemText>
              </ListItem>
            </NavLink>
          </TableCell>
          <Menu
            open={contextMenu !== null}
            onClose={handleClose}
            anchorReference="anchorPosition"
            anchorPosition={
              contextMenu !== null
                ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
                : undefined
            }
          >
            <MenuItem onClick={navigateToDetailPlaning}>
              Zur Feinplanung
            </MenuItem>
          </Menu>

          <TableCell>
            <div ref={ref as React.RefObject<HTMLDivElement>}>
              {clienteles.year} - KW {clienteles.weekOfYear}
            </div>
          </TableCell>
          <TableCell>
            <InputPeriodDate
                index={index}
              name="periodDate"
              control={control}
              openDate={openDate}
              toggleDate={toggleDate}
            />
          </TableCell>
          <TableCell align="right">
            <IconButton
              aria-label={t("expand-row")}
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? (
                <KeyboardArrowUpIcon
                  fontSize="large"
                  color={open ? "primary" : "inherit"}
                />
              ) : (
                <KeyboardArrowDownIcon
                  fontSize="large"
                  color={open ? "primary" : "inherit"}
                />
              )}
            </IconButton>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell className={classes.collapsibleTableCellStyles} colSpan={6}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              {clienteles.customers && (
                <Box display="flex" flexDirection="column">
                  <CustomerCollapsibleTable
                    customers={clienteles.customers}
                    year={clienteles.year}
                    weekOfYear={clienteles.weekOfYear}
                    clientele={clienteles.clientele}
                    importVersion={clienteles.version}
                  />
                </Box>
              )}
            </Collapse>
          </TableCell>
        </TableRow>
      </>
    );
  }
);

const useRowStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },

  collapsibleTableCellStyles: {
    paddingBottom: 0,
    paddingTop: 0,
  },

  collapseOpenStyles: {
    color: "#0d47a1",
  },

  iconStyles: {
    fontSize: "14px",
    color: "red",
    marginRight: "8px",
  },

  texStyles: {
    fontSize: "14px",
  },

  tableCellExpanded: {
    backgroundColor: "#e3f2fd",
  },

  tableCellNotExpanded: {
    backgroundColor: "white",
  },

  checkbox: {
    padding: 0,
    textAlign: "center",
    "& label": {
      margin: 0,
    },
  },
}));

export default CustomerTableRow;
