import { Box, Divider, Typography } from "@mui/material";
import {useFieldArray, useFormContext, useFormState} from "react-hook-form";
import {
  Article,
  ArticleAssignment,
  DetailedPlanningDetail,
  DetailedPlanningPosition,
  MarketingCampaign,
  PreviousAvpPriceResult,
} from "../../../api/detailedPlanningApiClient";
import { useTranslation } from "react-i18next";

import {
  Button,
  CircularProgress,
  ClickAwayListener,
  Grid,
  makeStyles,
  Modal,
  Popper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react";
import AssignmentDetails from "./AssignmentDetails";
import ConfirmDialog from "../../../components/ConfirmDialog";
import { InsertionArticle } from "./ArticleRow";
import { FormValues } from "../../layout/DetailedPlanningDetailsLayout";
import UnlistedArticle, { UnlistedArticleProps } from "./UnlistedArticle";
import CopyDetailedPlanningForm from "./CopyDetailedPlanningForm";
import NestedAssignmentsTableRow from "./NestedAssignmentsTableRow";
import { EXPORT_TYPE } from "../../../api/basedataApiClient";

interface AssignmentsTableProps {
  setLastAddedArticlePosition: React.Dispatch<
    React.SetStateAction<number | undefined>
  >;
  lastAddedArticlePosition: number | undefined;
  assignedPositions: DetailedPlanningPosition[];
  maxPositions: number;
  setExport: (exportType: EXPORT_TYPE) => void;
  sourceTemplatePages: string[];
  sourceTemplateName: string;
  loadingExport: boolean;
  onSubmit: () => void;
  onReset: () => void;
  pageIndex: number;
  getAvpPriceResult: (articleId: number) => void;
  addArticleToFlyerPage: (params: {
    marketingCampaign?: MarketingCampaign | null;
    article?: Article;
    insertionPosition?: { positionId: number; positionIndex: number };
    unlistedArticleName?: string;
  }) => void;
  setFieldsChanged: Dispatch<SetStateAction<boolean>>;
  fieldsChanged: boolean;
  setShowImportDialog: Dispatch<SetStateAction<boolean>>;
  setDetailedPlanning: React.Dispatch<
    React.SetStateAction<DetailedPlanningDetail | undefined>
  >;
  detailedPlanning: DetailedPlanningDetail;
  loadingSubmit: Boolean;
}

export interface handleDropValueProp {
  positionIndex: number;
  newPosition: DetailedPlanningPosition;
}

const useStyles = makeStyles((theme) => ({
  popper: {
    border: `1px solid #e1e4e8`,
    boxShadow: `0 8px 24px rgba(149, 157, 165, 0.2)`,
    borderRadius: 2,
    width: "auto",
    zIndex: theme.zIndex.modal,
    backgroundColor: "#fff",
  },
  popperButton: {
    width: "100%",
    textTransform: "initial",
    fontWeight: 400,
  },
}));

const reorder = (
  list: DetailedPlanningPosition[],
  startIndex: number,
  endIndex: number
) => {
  const [removed] = list.splice(startIndex, 1);
  list.splice(endIndex, 0, removed);
  return list.map<DetailedPlanningPosition>((position, index) => ({
    ...position,
    articleAssignments:
      position.articleAssignments?.map((assignment) => ({
        ...assignment,
        autoPlaced:
          startIndex > endIndex
            ? index >= endIndex && index <= startIndex
              ? false
              : assignment.autoPlaced
            : index >= startIndex && index <= endIndex
            ? false
            : assignment.autoPlaced,
        position: index,
      })) || [],
    linkedPositions: position.linkedPositions || [],
  }));
};

const AssignmentsTable = ({
  setLastAddedArticlePosition,
  lastAddedArticlePosition,
  assignedPositions,
  maxPositions,
  loadingExport,
  sourceTemplateName,
  sourceTemplatePages,
  getAvpPriceResult,
  setExport,
  onSubmit,
  onReset,
  pageIndex,
  addArticleToFlyerPage,
  setFieldsChanged,
  fieldsChanged,
  setShowImportDialog,
  detailedPlanning,
  setDetailedPlanning,
  loadingSubmit,
}: AssignmentsTableProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [showResetDialog, setShowResetDialog] = useState(false);
  const [exportType, setExportType] = useState("");
  const [openImportModal, setOpenImportModal] = useState(false);
  const [showIsDragEnabledDialog, setShowIsDragEnabledDialog] = useState(false);
  const [selectedFieldIndex, setSelectedFieldIndex] = useState<string | null>(
    null
  );
  const [anchorElExport, setAnchorElExport] = useState<any>(null);
  const [anchorElImport, setAnchorElImport] = useState<any>(null);

  const [handleDropValue, setHandleDropValue] = useState<handleDropValueProp>();
  const handleClickExport = (event: any) => {
    setAnchorElExport(event.currentTarget);
  };

  const handleClickImport = (event: any) => {
    setAnchorElImport(event.currentTarget);
  };

  const handleCloseExport = () => {
    if (anchorElExport) {
      anchorElExport.focus();
    }
    setAnchorElExport(null);
  };

  const handleCloseImport = () => {
    if (anchorElImport) {
      anchorElImport.focus();
    }
    setAnchorElImport(null);
  };

  const openExport = Boolean(anchorElExport);
  const openImport = Boolean(anchorElImport);
  const id = openExport ? "linking-positions" : undefined;
  const exportTypes = [
   // EXPORT_TYPE.EXPORTADPLAN,
    EXPORT_TYPE.INSERTIONSEXPORT,
    EXPORT_TYPE.CLIENTELEINTERN,
    EXPORT_TYPE.CLIENTELEINTERNALL,
    EXPORT_TYPE.CLIENTELEEXTERN,
    EXPORT_TYPE.CLIENTELEEXTERNALL,
   // EXPORT_TYPE.KASSENEXPORT,
    EXPORT_TYPE.KASSENEXPORTWEEK,
    EXPORT_TYPE.KASSENEXPORTALL,
   // EXPORT_TYPE.DRUCKVERSAND,
    EXPORT_TYPE.DRUCKVERSANDWEEK,
    EXPORT_TYPE.DRUCKVERSANDALL,
    EXPORT_TYPE.EXPORTPDF,
    EXPORT_TYPE.EXPORTPDF_MAILTO,
  ];

  const { control, watch, getValues, setValue } = useFormContext<FormValues>();
  const { dirtyFields } = useFormState({
    control,
  });

  const { update } = useFieldArray<FormValues>({
    name: `pages.${pageIndex}.positions`,
  });

  const watchedPositions = watch(`pages.${pageIndex}.positions`) || [];
  const handleReset = () => {
    onReset();
    setShowResetDialog(false);
    setLastAddedArticlePosition(undefined);
  };

  const setPosition = useCallback(
    (positionIndex: number, assignment: ArticleAssignment) => {
      setFieldsChanged(true);

      const formValues = getValues();
      let newPages = [...formValues.pages];

      const newPagesFiltered = newPages.map((page, index) => ({
        name: detailedPlanning.pages[index].name,
        maxPositions: detailedPlanning.pages[index].maxPositions,
        positions: page.positions.filter((pos) =>
          pos.hasOwnProperty("articleAssignments")
        ),
      }));
      let newDetailedPlanning = { ...detailedPlanning };
      newDetailedPlanning.pages = newPagesFiltered;
      let pageTo = newDetailedPlanning.pages[pageIndex];
      let newPosition;
      if (pageTo.hasOwnProperty("positions")) {
        if (pageTo.positions) {
          const positionToUpdate =
            pageTo.positions?.filter(
              (pos) => pos.articleAssignments![0]?.position === positionIndex
            ) || [];
          if (positionToUpdate[0]) {
            pageTo.positions = pageTo.positions?.map((position) => {
              if (position === positionToUpdate[0]) {
                newPosition = {
                  ...position,
                  articleAssignments: [
                    ...position.articleAssignments,
                    ...[assignment],
                  ],
                };
                return newPosition;
              } else {
                newPosition = position;
                return newPosition;
              }
            });
          } else {
            newPosition = {
              articleAssignments: [assignment],
              linkedPositions: [],
            };
            pageTo.positions.push(newPosition);
          }
        }
      }
      if (newPosition)
        setHandleDropValue({
          positionIndex: positionIndex,
          newPosition: newPosition,
        });
      setDetailedPlanning(newDetailedPlanning as DetailedPlanningDetail);
      setLastAddedArticlePosition(positionIndex);
    },
    [
      detailedPlanning,
      pageIndex,
      setFieldsChanged,
      setLastAddedArticlePosition,
      setDetailedPlanning,
      getValues,
    ]
  );

  const handleDrop = useCallback(
    (
      insertionArticle: InsertionArticle,
      positionIndex: number,
      avpPriceResult: PreviousAvpPriceResult | undefined
    ) => {
      const assignment = {
        adPlanningPositionId: insertionArticle.position?.positionId || null,
        marketingCampaignId:
          insertionArticle.marketingCampaign?.marketingCampaignId || null,
        articleId: insertionArticle.article.articleId,
        unlistedArticleName: insertionArticle.article.articleName,
        articleRange: false,
        avpPrice: avpPriceResult ? avpPriceResult.avpPrice : 0,
        avpWeek: avpPriceResult?.weekOfYear,
        avpYear: avpPriceResult?.year,
        lvpPrice: avpPriceResult ? avpPriceResult.lvpPrice : 0,
        eyeCatcher: "",
        position: positionIndex,
        processingNote: "",
        graphicNote: "",
        poster: false,
        paperSize: null,
        coverPage: insertionArticle.article.coverPage,
        hero: insertionArticle.article.hero,
        tailorMade: insertionArticle.article.tailorMade,
        onlineCampaign: insertionArticle.article.onlineCampaign,
        offerDiscount: insertionArticle.article.offerDiscount,
        customQuality: insertionArticle.article.customQuality,
        customQualityDescription:
          insertionArticle.article.customQualityDescription,
        numberOfSalesMarkets: insertionArticle.article.numberOfSalesMarkets,
        totalNumberOfSalesMarkets:
          insertionArticle.article.totalNumberOfSalesMarkets,
        obligationRange: insertionArticle.article.obligationRange,
        customQuality2: insertionArticle.article.customQuality2,
        customQualityDescription2:
          insertionArticle.article.customQualityDescription2,
        customQuality3: insertionArticle.article.customQuality3,
        customQualityDescription3:
          insertionArticle.article.customQualityDescription3,
        customQuality4: insertionArticle.article.customQuality4,
        customQualityDescription4:
          insertionArticle.article.customQualityDescription4,
        customQuality5: insertionArticle.article.customQuality5,
        customQualityDescription5:
          insertionArticle.article.customQualityDescription5,
        customQuality6: insertionArticle.article.customQuality6,
        customQualityDescription6:
          insertionArticle.article.customQualityDescription6,
        actConversion: insertionArticle.article.actConversion,
        nomConversion: insertionArticle.article.nomConversion,
      };
      setPosition(positionIndex, assignment);
    },
    [setPosition]
  );

  const handleUnlistedDrop = useCallback(
    (insertionUnlistedArticle: UnlistedArticleProps, positionIndex: number) => {
      const assignment = {
        adPlanningPositionId: null,
        marketingCampaignId: null,
        articleId: null,
        unlistedArticleName: insertionUnlistedArticle.unlistedArticleName,
        articleRange: false,
        avpPrice: 0,
        avpWeek: undefined,
        avpYear: undefined,
        lvpPrice: 0,
        eyeCatcher: "",
        position: positionIndex,
        processingNote: "",
        graphicNote: "",
        poster: false,
        paperSize: null,
      };
      setPosition(positionIndex, assignment);
    },
    [setPosition]
  );

  const handleDragEnd = (dragIndex: number, hoverIndex: number) => {
    const isDragEnabled =
      watchedPositions.flatMap((c) => c.linkedPositions).filter(Boolean)
        .length === 0;
    if (isDragEnabled) {
      setFieldsChanged(true);
      const formValues = getValues();

      let newPages = [...formValues.pages];
      let newDetailedPlanning = { ...detailedPlanning };

      const newPagesFiltered = newPages.map((page, index) => ({
        name: detailedPlanning.pages[index].name,
        maxPositions: detailedPlanning.pages[index].maxPositions,
        positions: page.positions.filter((pos) =>
          pos.hasOwnProperty("articleAssignments")
        ),
      }));
      newDetailedPlanning.pages = newPagesFiltered;

      let newPositions = reorder(
        [...formValues.pages[pageIndex].positions],
        dragIndex,
        hoverIndex
      );
      const newPositionsFiltered = newPositions.filter(
        (pos) => pos.articleAssignments.length !== 0
      );

      let page = newDetailedPlanning.pages[pageIndex];
      page.positions = newPositionsFiltered;

      // TODO: for performance optims -> remove setValue but lvp + avp getting wrong values on handleDragEnd
      setValue(`pages.${pageIndex}.positions`, page.positions);
      setDetailedPlanning(newDetailedPlanning as DetailedPlanningDetail);
      if (dragIndex === lastAddedArticlePosition) {
        setLastAddedArticlePosition(hoverIndex);
      }
    } else {
      setShowIsDragEnabledDialog(true);
    }
  };

  const removeLinkedPositions = () => {
    watchedPositions.forEach((p, i) => {
      setValue(`pages.${pageIndex}.positions.${i}.linkedPositions`, []);
    });
  };

  useEffect(() => {
    if (
      handleDropValue !== undefined &&
      handleDropValue.newPosition &&
      handleDropValue.positionIndex
    ) {
      update(handleDropValue.positionIndex, handleDropValue.newPosition);
    }
  }, [handleDropValue, update]);

  return (
    <Box
      style={{
        height: "auto",
        display: "flex",
        flexDirection: "column",
        marginBottom: "5rem",
      }}
    >
      <Box
        display="flex"
        flexDirection="row-reverse"
        color="textSecondary"
        pb={1}
      >
        <Typography component="span" variant="subtitle2" color="textSecondary">
          {` ${assignedPositions.length} von ${maxPositions}`}
        </Typography>
      </Box>
      <Box>
        <Divider />
      </Box>
      <Box flexGrow={1} marginTop={2}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell style={{ fontWeight: "bold", border: 0 }}></TableCell>
              <TableCell style={{ width: "4em" }}></TableCell>
              <TableCell style={{ paddingLeft: "20px" }}>Artikel</TableCell>
              <TableCell>Störer</TableCell>
              <TableCell>Range</TableCell>
              <TableCell align="left">Preis(lvp)</TableCell>
              <TableCell align="left">Preis(avp)</TableCell>
              <TableCell align="right">Einsparung</TableCell>
              <TableCell style={{ fontWeight: "bold", border: 0 }}></TableCell>
            </TableRow>
          </TableHead>
          <TableBody key="assignment-table">
            {watchedPositions.map((position, index) => (
              <NestedAssignmentsTableRow
                key={"nested-row-" + index}
                position={position}
                setLastAddedArticlePosition={setLastAddedArticlePosition}
                lastAddedArticlePosition={lastAddedArticlePosition}
                pageIndex={pageIndex}
                positionIndex={index}
                selectedFieldIndex={selectedFieldIndex}
                setSelectedFieldIndex={setSelectedFieldIndex}
                getAvpPriceResult={getAvpPriceResult}
                setFieldsChanged={setFieldsChanged}
                handleUnlistedDrop={handleUnlistedDrop}
                handleDrop={handleDrop}
                handleDragEnd={handleDragEnd}
              />
            ))}
          </TableBody>
        </Table>
      </Box>

      <Box
        sx={{
          mt: 2,
          position: "fixed",
          bottom: "0",
          left: "0",
          right: "0",
          padding: "0 37px 16px 105px",
          zIndex: "999",
        }}
      >
        <Grid container justifyContent="flex-end">
          <Grid
            style={{
              border: "1px solid rgba(0, 0, 0, 0.12)",
              background: "white",
              padding: "0 1rem",
            }}
            item
            xs={8}
          >
            {watchedPositions.map((position, index) =>
              position.articleAssignments?.length
                ? position.articleAssignments?.map((article, articleIndex) => (
                    <Box key={"assignmentbox-" + index + articleIndex}>
                      {selectedFieldIndex ===
                        "assignment-" + index + "-" + articleIndex && (
                        <AssignmentDetails
                          assignment={
                            watchedPositions[index].articleAssignments[
                              articleIndex
                            ]
                          }
                          name={
                            `pages.${pageIndex}.positions.${index}.articleAssignments.${articleIndex}` as const
                          }
                          control={control}
                          isPoster={
                            watchedPositions[index].articleAssignments[
                              articleIndex
                            ]?.poster
                          }
                        />
                      )}
                    </Box>
                  ))
                : ""
            )}
            <Box>
              {loadingExport && (
                <CircularProgress
                  style={{
                    position: "absolute",
                    left: "40%",
                    right: "0",
                    margin: "auto",
                    top: "12px",
                  }}
                />
              )}
              <Box
                display="flex"
                justifyContent="end"
                alignItems="center"
                mb={1}
                mt={2}
                style={{
                  transition: "all 0.25s ease",
                  pointerEvents: loadingExport ? "none" : "unset",
                }}
              >
                <Button
                  variant="outlined"
                  color="primary"
                  style={{
                    opacity: loadingExport ? "0.5" : "1",
                  }}
                  onClick={() => {
                    setShowResetDialog(true);
                  }}
                >
                  {t("reset-to-default")}
                </Button>
                <UnlistedArticle
                  lastAddedArticlePosition={lastAddedArticlePosition}
                  addArticleToFlyerPage={addArticleToFlyerPage}
                />
                <ConfirmDialog
                  title={t("reset-detailed-planning")}
                  open={showResetDialog}
                  message={t("reset-detailed-planning-dialog")}
                  handleConfirm={handleReset}
                  handleReject={() => {
                    setShowResetDialog(false);
                  }}
                  ariaLabel="reset-detailed-planning"
                  ariaDescription="reset-detailed-planning-dialog"
                  confirmBtnText={t("reset-to-default")}
                />
                <ConfirmDialog
                  title={t("export-detailed-planning")}
                  open={exportType === "" ? false : true}
                  message={t("export-detailed-planning-dialog")}
                  handleConfirm={() => {
                    setExport(exportType as EXPORT_TYPE);
                    setExportType("");
                  }}
                  handleReject={() => {
                    setExportType("");
                  }}
                  ariaLabel="export-detailed-planning"
                  ariaDescription="export-detailed-planning-dialog"
                  confirmBtnText={t("export")}
                />
                <ConfirmDialog
                  title={t("isdraggable-detailed-planning")}
                  open={showIsDragEnabledDialog}
                  message={t("isdraggable-detailed-planning-dialog")}
                  handleConfirm={() => {
                    removeLinkedPositions();
                    setShowIsDragEnabledDialog(false);
                  }}
                  handleReject={() => {
                    setShowIsDragEnabledDialog(false);
                  }}
                  ariaLabel="isdraggable-detailed-planning"
                  ariaDescription="isdraggable-detailed-planning-dialog"
                  confirmBtnText={t("delete-linked-positions")}
                />
                <Button
                  onClick={handleClickImport}
                  variant="contained"
                  color="primary"
                  style={{
                    marginRight: "1rem",
                    opacity: loadingExport ? "0.5" : "1",
                  }}
                >
                  {t("import")}
                  <KeyboardArrowUpIcon
                    style={{ marginBottom: "2px", height: "20px" }}
                  />
                </Button>
                <Popper
                  className={classes.popper}
                  id={"import-button-popper"}
                  open={openImport}
                  anchorEl={anchorElImport}
                  placement="top-start"
                >
                  <ClickAwayListener onClickAway={handleCloseImport}>
                    <div>
                      <TableRow>
                        <Button
                          className={classes.popperButton}
                          onClick={() => setOpenImportModal(true)}
                          variant="text"
                        >
                          {t("import-from-template")}
                        </Button>
                      </TableRow>
                      <TableRow>
                        <Button
                          className={classes.popperButton}
                          onClick={() => setShowImportDialog(true)}
                          variant="text"
                        >
                          {t("auto-import")}
                        </Button>
                      </TableRow>
                    </div>
                  </ClickAwayListener>
                </Popper>
                <Modal
                  open={openImportModal}
                  onClose={() => setOpenImportModal(false)}
                >
                  <div>
                    <CopyDetailedPlanningForm
                      setOpenImportModal={setOpenImportModal}
                      sourceTemplateName={sourceTemplateName}
                      sourceTemplatePages={sourceTemplatePages}
                    />
                  </div>
                </Modal>
                <Button
                  onClick={handleClickExport}
                  variant="contained"
                  color="primary"
                  style={{
                    marginRight: "1rem",
                    paddingRight: "5px",
                    opacity: loadingExport ? "0.5" : "1",
                  }}
                >
                  {t("export")}
                  <KeyboardArrowUpIcon
                    style={{ marginBottom: "2px", height: "20px" }}
                  />
                </Button>
                <Popper
                  className={classes.popper}
                  id={"export-button-" + id}
                  open={openExport}
                  anchorEl={anchorElExport}
                  placement="top-start"
                >
                  <ClickAwayListener onClickAway={handleCloseExport}>
                    <div>
                      {exportTypes.map((type: EXPORT_TYPE, index: number) => (
                        <TableRow key={"exportTable-" + index}>
                          <Button
                            className={classes.popperButton}
                            onClick={() => {
                              if (
                                (dirtyFields.pages &&
                                  dirtyFields.pages[pageIndex]?.positions) ||
                                fieldsChanged
                              ) {
                                setExportType(type);
                              } else {
                                setExport(type);
                              }
                            }}
                            variant="text"
                          >
                            {t(type)}
                          </Button>
                        </TableRow>
                      ))}
                    </div>
                  </ClickAwayListener>
                </Popper>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  style={{
                    opacity: loadingExport || loadingSubmit ? "0.5" : "1",
                  }}
                  onClick={() => {
                    setFieldsChanged(false);
                    setSelectedFieldIndex(null);
                    onSubmit();
                  }}
                >
                  {loadingSubmit && (
                    <CircularProgress
                      style={{
                        width: "1.5em",
                        height: "1.5em",
                        position: "absolute",
                      }}
                      color={"secondary"}
                    />
                  )}
                  {t("apply")}
                </Button>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default AssignmentsTable;
